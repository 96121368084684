import config from '@config';

declare let window: any;

export class Tracker
{
	protected static _instance?: Tracker;
    enabled = true;
	trackWithGoogle = false;
	trackWithFacebook = false;
	trackWithApi = false;
	gtag?: Gtag.Gtag;
	fbq?: facebook.Pixel.Event;
	
    // Singleton
	protected constructor()
	{
		this.initGoogle();
		this.initFacebook();
	}

    static get instance(): Tracker
    {
        if (this._instance === undefined) this._instance = this.createInstance();

        return this._instance;
    }

    static createInstance(): Tracker
    {
        return new Tracker();
    }
	
	/**
	* Init the Google Analytics tracking code.
	* https://developers.google.com/analytics/devguides/collection/gtagjs
	* https://developers.google.com/tag-platform/tag-manager/web/datalayer
	*/
	private initGoogle()
	{
        //load the tracking script
        let script = document.createElement("script");
        script.src = 'https://www.googletagmanager.com/gtag/js?id=' +  config.googleAnalyticsId;
        script.async = true;
        document.body.appendChild(script);
        
        //initialize the data layer (events in the data layer will be sent to Google as soon as the gtag script has been loaded)
        window.dataLayer = window.dataLayer || [];
        this.gtag = function () {window.dataLayer.push(arguments);}
        this.gtag('js', new Date());
        this.gtag('config', config.googleAnalyticsId, {'send_page_view': false});
	}
	
	/**
	* Initialize Facebook pixel code.
	* https://www.facebook.com/business/help/952192354843755?id=1205376682832142
	*/
	private initFacebook()
	{
        const f = function(f?:any,b?:any,e?:any,v?:any,n?:any,t?:any,s?:any)
        {
            if (f.fbq) return;
            n=f.fbq=function()
            {
                n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments)
            };
            if (!f._fbq) f._fbq=n;
            n.push=n;
            n.loaded=!0;
            n.version='2.0';
            n.queue=[];
            t=b.createElement(e);
            t.async=!0;
            t.src=v;
            s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)
        };
        f(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
        //fbq('set', 'autoConfig', 'false', config.facebookPixelID); 
        fbq('init', config.facebookPixelId);
        fbq('track', 'PageView');
        this.fbq = fbq;
	}
	
	/**
	* Log a custom event.
	* Event anatomy: https://support.google.com/analytics/answer/1033068#Anatomy.
	* 
	* @param name 
	* @param category 
	* @param label
	* @param value
	* @param parameters 
	*/
	logEvent(name: string, category?: string, label?: string, value?: number, parameters?: any)
	{
        //setup parameters
		if (parameters == null) parameters = {};
        parameters.version = config.version;
		parameters.event_category = category;
		parameters.event_label = label;
		parameters.value = value;
		
		//Google
		if (this.trackWithGoogle) this.logGoogleEvent(name, parameters);
		
		//Facebook
		if (this.trackWithFacebook) this.logFacebookEvent(name, parameters);
		
		//xFrame tracker
		if (this.trackWithApi) this.logTrackerEvent(name, parameters);
	}
	
	/**
	* Log a page view (Google only).
	* https://developers.google.com/analytics/devguides/collection/gtagjs/pages
	*/
	logPageView(pageTitle?: string, pageLocation?: string, pagePath?: string)
	{
        //Google
		let parameters = {
            page_title: pageTitle,
            page_location: pageLocation,
            page_path: pagePath,
        };
        this.logGoogleEvent("page_view", parameters);
	}
	
	/**
	* https://developers.google.com/analytics/devguides/collection/ga4/ecommerce#purchase
	* https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking/
	* https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
	* Currency and value are valid parameter values for both Google and Facebook.
	* 
	* @param idTransaction The transaction ID.    
	* @param idProduct The product ID.
	* @param productName The product name.
	* @param productCategory The product category.
	* @param productQuantity Quantity.
	* @param productPrice Price.
	* @param currency Currency 3 letter code.
	*/
	logPurchase(idTransaction: string, idProduct: string, productName: string, productCategory: string, productQuantity: number, productPrice: number, currency: string)
	{
		let parameters = {
			"transaction_id": idTransaction,
			"value": productQuantity * productPrice,
			"currency": currency,
			"items": [
				{
					"item_id": idTransaction,
					"item_name": productName,
					"item_category": productCategory,
					"quantity": productQuantity,
					"price": productPrice,
				}
			]
		};
		
		//Google
		if (this.trackWithGoogle) this.logGoogleEvent("purchase", parameters);
		
		//Facebook
		if (this.trackWithFacebook) this.logFacebookEvent("Purchase", parameters);
		
		//xFrame tracker
		if (this.trackWithApi) this.logTrackerEvent("purchase", parameters);
	}   
	
	/**
	* Log a Google event with gtag.js.
	* https://developers.google.com/analytics/devguides/collection/gtagjs/events
	* 
	* @param name Event name. 
	* @param parameters Event parameters.
	*/
	private logGoogleEvent(name: string, parameters: any = {})
	{
        if (!this.enabled || !this.trackWithGoogle || this.gtag === undefined) return;
		
		this.gtag("event", name, parameters);
	}
	
	/**
	* Log a Facebook pixel event via JS
	* https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking/
	* 
	* @param name Event name. 
	* @param parameters Event parameters.
	*/
	private logFacebookEvent(name: string, parameters: any = {})
	{
		if (!this.enabled || !this.trackWithFacebook || this.fbq === undefined) return;
		
		this.fbq('trackCustom', name, parameters);
	}
	
	/** 
	* Log event to the local database.
	* 
	* @param name Event name. 
	* @param parameters Event parameters.
	*/
	private logTrackerEvent(name: string, parameters: any)
	{
        throw new Error('NOT_IMPLEMENTED');
	}
	
	/**
	* Log a Javascript error to the database.
	* 
	* @param message 
	* @param url 
	* @param lineNumber 
	* @param columnNumber 
	* @param errorObject 
	*/
	logError(message: string, url?: string, lineNumber?: number, columnNumber?: number, errorObject?: Error)
	{
		if (!this.enabled) return;
		
		let parameters = {
			message: message,
			url: url,
			lineNumber: lineNumber,
			columnNumber: columnNumber,
			stack: errorObject?.stack,
		}
		
		if (this.trackWithApi) this.logTrackerEvent("js_error", parameters);
	}
}

export default Tracker;