import { FunctionComponent } from 'react';
import Script from 'next/script'
import { useTranslate } from '@xFrame4/common/Hooks';
import config from '@config';

interface FooterProps
{

}

const Footer: FunctionComponent<FooterProps> = (props) =>
{
    const t = useTranslate();
    
    /** Render */
    return (
        <>
            <footer data-aos="" id="footer" data-bg="white" className="aos-init aos-animate" data-padding="top">
                <div className="container">
                    <div className="row">
                        <div className="col-12 down border">
                            <div className="row">
                                <div className="col-6">
                                    <div className="list-element--text--inline">
                                        <ul>
                                            <li><a href={config.defaultPublicDirBaseUrl + 'static/privacy-policy'}>{t('PRIVACY-POLICY').toLowerCase()}</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-6 text-right">
                                    {/* <p style={{paddingTop: '4px'}}>© 2021 by <a href="https://www.learnlogics.ro/" className="simple" target="_blank" rel="noreferrer">LearnLogics</a></p> */}
                                    <p style={{paddingTop: '4px'}}>© 2021, {t('APP_TITLE')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <Script src={config.defaultPublicDirBaseUrl + 'dedafo/assets/js/isotope.min.js'}></Script>
            <Script src={config.defaultPublicDirBaseUrl + 'dedafo/assets/js/imagesloaded.min.js'}></Script>
            <Script src={config.defaultPublicDirBaseUrl + 'dedafo/assets/js/carousel.js'}></Script>
            <Script src={config.defaultPublicDirBaseUrl + 'dedafo/assets/js/menu.js'}></Script>
            <Script src={config.defaultPublicDirBaseUrl + 'dedafo/assets/js/elements.js'}></Script>
            <Script src={config.defaultPublicDirBaseUrl + 'dedafo/assets/js/masonry.js'}></Script>
            <Script src={config.defaultPublicDirBaseUrl + 'dedafo/assets/js/form.js'}></Script>
        </>
    );
}

export default Footer;