import { FunctionComponent } from 'react';
import Footer from './Footer';
import Header from './Header';

export interface PropsWithChildren
{
    children?: JSX.Element[] | JSX.Element;
}

interface MainWrapProps extends PropsWithChildren
{
    
}

const MainWrap: FunctionComponent<MainWrapProps> = (props) =>
{
    /** Render */
    return (
        <>
            <div id="main-wrap">
                <Header />
                {props.children}
                <Footer />
            </div>
        </>
    );
}

export default MainWrap;