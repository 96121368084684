/**
 * Generate a unique ID string.
 * 
 * @returns The unique ID.
 */
export function uniqueId()
{
    return Math.random().toString(36).substring(2, 9);
}

/**
 * Generate a random integer.
 * 
 * @param min 
 * @param max 
 * @returns The random int.
 */
export function getRandomInt(min: number, max: number)
{
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}

/**
 * Calculates if the HTML element is above the lazy load threshold. Can be used while scrolling.
 * 
 * @param element The HTML element.
 * @param thresholdInPixels A distance in pixels to the bottom of the screen.
 */
export function isElementAboveThreshold(element: HTMLElement, thresholdInPixels: number = 0)
{
    //the distance between the top of the window and the top of the image
    let imageTop = element.getBoundingClientRect().y as number;
    //the height of the window
    let windowHeight = window.innerHeight;

    //Normaly (if thresholdInPixels is 0) the image is above threshold if the top of the image is visible.
    let isAboveTreshold = imageTop <= windowHeight + thresholdInPixels;

    return isAboveTreshold;
};

/**
 * Return an image URL the forces image refresh in the browser.
 * 
 * @param imageUrl The URL of the image
 */
export function freshImageUrl(imageUrl: string)
{
    if (imageUrl != null)
    {
        let glue = imageUrl.indexOf('?') == -1 ? '?' : '&';
        
        return imageUrl + glue + Math.random();
    }
    else
    {
        return '';
    }
}

/**
     * Return the GMT date in YYYY-mm-dd format.
     * 
     * @param date A Date or a timestamp.
     * @returns 
     */
export function formatDate(date: Date | string | number)
{
    let d = new Date(date);
    let month = '' + (d.getUTCMonth() + 1); // Need to add one because getMonth() is 0 based.
    let day = '' + d.getUTCDate();
    let year = d.getUTCFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

/**
 * Return the GMT time in hh:mm:ss format.
 * 
 * @param date A Date or a timestamp.
 * @returns
 */
export function formatTime(date: Date | string | number)
{
    let d = new Date(date);
    let hours = '' + d.getUTCHours();
    let minutes = '' + d.getUTCMinutes();
    let seconds = '' + d.getUTCSeconds();

    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;
    if (seconds.length < 2) seconds = '0' + seconds;

    return [hours, minutes, seconds].join(':');
}

/**
 * Return the GMT datetime to YYYY-mm-dd hh:mm:ss
 * 
 * @param date A Date or a timestamp.
 * @returns
 */
export function formatDateTime(date: Date | string | number)
{
    return formatDate(date) + ' ' + formatTime(date);
}

/**
 * The current GMT date and time in YYYY-mm-dd hh:mm:ss format.
 */
export function now()
{
    return formatDateTime((new Date()));
}

/**
 * Get the value for the dataSourceDisplayMember.
 * 
 * @param item The datasource item (row).
 * @param dataSourceDisplayMember The display member.
 * @returns 
 */
export function getDataSourceDisplayMemberValue(item: any, dataSourceDisplayMember: string)
{
    let displayValue: any;

    if (!dataSourceDisplayMember.includes('.'))
    {// Simple member
        displayValue = item[dataSourceDisplayMember];
    }
    else
    {// Complex member. Eg: actor.person.lastName
        let memberObject = item;

        let fieldSplits = dataSourceDisplayMember.split('.');
        for (let fieldSplit of fieldSplits)
        {
            memberObject = memberObject[fieldSplit];
        }

        if (memberObject !== undefined) displayValue = memberObject;
    }

    return displayValue;
}