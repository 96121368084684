import { AppTracker } from '@business/tracking/AppTracker';
import ALink from '@xFrame4/components/next/ALink';
import SmoothImage from '@xFrame4/components/SmoothImage';
import Link from 'next/link';
import { FunctionComponent, PropsWithChildren, useEffect } from 'react';

interface HeaderProps
{

}

const Header: FunctionComponent<HeaderProps> = (props) =>
{
    /** Track pageview */
    useEffect(() => {
        AppTracker.instance.logPageView();
    }, []);
    
    /** Render */
    return (
        <>
            <header id="header">
                <div className="wrap-header--padding-lg">
                    <nav className="main-navigation">
                    <div id="logo" className="height-lg">
                        <ALink className="navbar-brand" href="/">
                            <SmoothImage 
                                src="/img/logo/logo.png"
                                width={1204}
                                height={696}
                                className="header-logo"
                            />
                        </ALink>
                    </div>
                    </nav>
                </div>
            </header>
        </>
    );
}

export default Header;